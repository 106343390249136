<template lang="pug">
  CaseForm(:data="data")
</template>

<script>
import CaseForm from './CaseForm'

export default {
  name: 'CaseEdit',
  components: {
    CaseForm
  },
  data () {
    return {
      data: {
        id: undefined
      }
    }
  },
  computed: {},
  methods: {
    async getDetail() {
      if (!this.$route.params.id) return;

      const result = await this.$http.get(`case/${this.$route.params.id}`);
      this.data = result;
    },

  },
  created() {
    this.getDetail();
  }
}
</script>

<style lang="less" scoped></style>